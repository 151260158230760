import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import makeCancellablePromise from 'make-cancellable-promise';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setDefaultOptions, format, startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { RiCheckboxCircleFill, RiCloseCircleFill, RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      pageLoad: true,
      responseApi: '',
      date: '',
      longPrev: [],
      longNext: [],
      joint: true,
      holidayWeekend: false,
      saturday: true,
      longWeekend: true,
    }

    this.copy = 2024;
    this.today = new Date();
    // this.today = new Date(2024, 9, 1);
    this.day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.month = [{
      label: 'January', value: 1
    }, {
      label: 'February', value: 2
    }, {
      label: 'March', value: 3
    }, {
      label: 'April', value: 4
    }, {
      label: 'May', value: 5
    }, {
      label: 'June', value: 6
    }, {
      label: 'July', value: 7
    }, {
      label: 'August', value: 8
    }, {
      label: 'September', value: 9
    }, {
      label: 'October', value: 10
    }, {
      label: 'November', value: 11
    }, {
      label: 'December', value: 12
    }];

    this.cancellableLoad = '';

    this.switchJoint = this.switchJoint.bind(this);
    this.switchSaturday = this.switchSaturday.bind(this);
    this.switchLongWeekend = this.switchLongWeekend.bind(this);
    this.switchHolidayWeekend = this.switchHolidayWeekend.bind(this);
    this.generateCalendar = this.generateCalendar.bind(this);
  }

  componentDidMount(){
    setDefaultOptions({ locale: enUS });

    this.cancellableLoad = makeCancellablePromise(
      axios.get('https://dayoffapi.vercel.app/api?year=' + this.today.getFullYear())
    );

    this.cancellableLoad.promise.then((resultApi) => {
      this.generateCalendar(resultApi);
      this.setState({responseApi: resultApi});
    }).catch((error) => {
      console.error(error);

      toast.error(() => (<>{error.code}<span>{error.message}</span></>));

      this.setState({ pageLoad: false });
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(
      this.state.joint !== prevState.joint ||
      this.state.saturday !== prevState.saturday ||
      this.state.longWeekend !== prevState.longWeekend ||
      this.state.holidayWeekend !== prevState.holidayWeekend
    ){
      this.generateCalendar(this.state.responseApi);
    }
  }

  componentWillUnmount(){
    if(this.cancellableLoad){
      this.cancellableLoad.cancel();
    }
  }

  switchJoint(){
    this.setState({ joint: !this.state.joint });
  }

  switchSaturday(){
    this.setState({ saturday: !this.state.saturday });
  }

  switchLongWeekend(){
    this.setState({ longWeekend: !this.state.longWeekend });
  }
  
  switchHolidayWeekend(){
    this.setState({ holidayWeekend: !this.state.holidayWeekend });
  }
  
  generateCalendar(resultApi){
    const getYear = this.today.getFullYear();
        
    let listMonth = [],
        countYear = 1,
        countYearPublic = 0;

    for(let iMonth = 1; iMonth <= 12; iMonth++){
      const startMonth = startOfMonth(new Date(getYear, iMonth, 0, 0, 0, 0)),
            endMonth = endOfMonth(new Date(getYear, iMonth, 0, 0, 0, 0)),
            monthDayDiff = differenceInDays(endMonth, startMonth) + 1;

      let listDay = [],
          countPublic = 0,
          countSandwich = 0;

      for(let iDay = 1; iDay <= monthDayDiff; iDay++){
        const dayTimestamp = new Date(getYear, iMonth - 1, iDay),
              findHoliday = resultApi.data.find(x => x.tanggal === format(dayTimestamp, 'yyyy-MM-d')),
              name = dayTimestamp.toLocaleString('default', { weekday: 'long' });

        let weekend = false,
            holiday = '',
            today = false;
            
        if(typeof findHoliday !== 'undefined' && (this.state.joint ? true : !findHoliday.is_cuti)){
          holiday = findHoliday.keterangan;
          countPublic++;
        }

        if(name === 'Sunday' || (this.state.saturday && name === 'Saturday')){
          weekend = true;
        }
        
        if((typeof findHoliday !== 'undefined' && (this.state.joint ? true : !findHoliday.is_cuti)) || name === 'Sunday' || (this.state.saturday && name === 'Saturday')){
          if(this.state.longWeekend){
            if(countSandwich === 1){
              if(listDay.length === 1){
                const getLastMonth = listMonth[listMonth.length - 1],
                      getLastMonthDay = typeof getLastMonth !== 'undefined' ? getLastMonth.day[getLastMonth.day.length - 1] : '';
    
                if(getLastMonthDay.weekend && getLastMonthDay.holiday){
                  listDay[listDay.length - 1].sandwich = true;
                }
              }else{
                listDay[listDay.length - 1].sandwich = true;
              }
            }

            if(countSandwich === 2){
              if(listDay.length === 2){
                const getLastMonth = listMonth[listMonth.length - 1],
                      getLastMonthDay = typeof getLastMonth !== 'undefined' ? getLastMonth.day[getLastMonth.day.length - 1] : '';
    
                if(getLastMonthDay.weekend && getLastMonthDay.holiday){
                  listDay[listDay.length - 1].sandwich = true;
                  listDay[listDay.length - 2].sandwich = true;
                }
              }else{
                listDay[listDay.length - 1].sandwich = true;
                listDay[listDay.length - 2].sandwich = true;
              }
            }

            countSandwich = 0;
          }
        }else{
          if(this.state.longWeekend){
            countSandwich++;
          }
        }
        
        if(this.state.longWeekend && listDay.length === 0 && (weekend || holiday)){
          const getLastMonth = listMonth[listMonth.length - 1],
                getLastMonthDayOne = typeof getLastMonth !== 'undefined' ? getLastMonth.day[getLastMonth.day.length - 1] : '',
                getLastMonthDayTwo = typeof getLastMonth !== 'undefined' ? getLastMonth.day[getLastMonth.day.length - 2] : '',
                getLastMonthDayThree = typeof getLastMonth !== 'undefined' ? getLastMonth.day[getLastMonth.day.length - 3] : '';
                
          if(typeof getLastMonth !== 'undefined' && getLastMonthDayOne && getLastMonthDayTwo){
            if(getLastMonthDayThree.weekend || getLastMonthDayThree.holiday){
              if(!getLastMonthDayTwo.weekend && !getLastMonthDayTwo.holiday && !getLastMonthDayOne.weekend && !getLastMonthDayOne.holiday){
                getLastMonthDayTwo.sandwich = true;
              }

              if(!getLastMonthDayOne.weekend && !getLastMonthDayOne.holiday){
                getLastMonthDayOne.sandwich = true;
              }
            }
          }
        }
        
        if(this.today.getTime() === dayTimestamp.getTime()){
          today = true;
        }
        
        listDay.push({
          day: iDay,
          dayYear: countYear,
          name: name,
          weekend: weekend,
          holiday: holiday,
          holidayCount: holiday ? countYearPublic + countPublic : '',
          sandwich: false,
          today: today,
          timestamp: dayTimestamp,
          timeFormat: format(dayTimestamp, 'd MMMM yyyy')
        });
        
        if(this.state.longWeekend){
          if(iMonth === 12 && iDay === 31){
            if(listDay[28].weekend || listDay[28].holiday){
              if(!listDay[29].weekend && !listDay[29].holiday){
                listDay[29].sandwich = true;
              }
              
              if(!listDay[30].weekend && !listDay[30].holiday){
                listDay[30].sandwich = true;
              }
            }

            if(listDay[29].weekend || listDay[29].holiday){
              if(!listDay[30].weekend && !listDay[30].holiday){
                listDay[30].sandwich = true;
              }
            }
          }
        }
        
        countYear++;
      }
      
      listMonth.push({
        day: listDay
      });

      countYearPublic = countYearPublic + countPublic;
    }
    
    let closestDay = '',
        listCompare = [];

    listMonth.forEach((valMonth, indMonth) => {
      valMonth.day.forEach((valDay, indDay) => {
        if(this.today.getTime() < valDay.timestamp.getTime() && (valDay.weekend === true || valDay.holiday !== '' || valDay.sandwich === true)){
          listCompare.push(valDay);
        }

        if(closestDay === '' && this.today.getTime() < valDay.timestamp.getTime() && (this.state.holidayWeekend ? valDay.holiday !== '' && !valDay.weekend : valDay.holiday)){
          closestDay = valDay;
        }
      });
    });
    
    closestDay.dayDiff = differenceInDays(closestDay.timestamp, this.today);
    
    const findList = listCompare.findIndex(x => x.timestamp.getTime() === closestDay.timestamp.getTime());

    let prevLong = [],
        prevDay = closestDay.timestamp,
        nextLong = [],
        nextDay = closestDay.timestamp;

    for(let iPrev = findList - 1; iPrev >= 0; iPrev--){
      if(Math.abs(differenceInDays(listCompare[iPrev].timestamp, prevDay)) === 1){
        prevLong.push(listCompare[iPrev]);
        prevDay = listCompare[iPrev].timestamp;
      }else{
        iPrev = -1;
      }
    }

    for(let iNext = findList + 1; iNext < listCompare.length; iNext++){
      if(Math.abs(differenceInDays(listCompare[iNext].timestamp, nextDay)) === 1){
        nextLong.push(listCompare[iNext]);
        nextDay = listCompare[iNext].timestamp;
      }else{
        iNext = listCompare.length + 1;
      }
    }
    
    this.setState({
      date: closestDay,
      longPrev: prevLong.reverse(),
      longNext: nextLong,
      pageLoad: false
    });
  }

  renderAkhir(d){
    if (d > 3 && d < 21) return 'th';

    switch (d % 10) {
      case 1:  return 'st';
      case 2:  return 'nd';
      case 3:  return 'rd';
      default: return 'th';
    }
  }

  renderCard(value){
    return(
      <div className={`${value.holiday || value.weekend ? 'holiday' : ''} ${value.sandwich ? 'sandwich' : ''}`}>
        {value.weekend && value.holiday ? (<span></span>) : ''}
        <ul>
          <li>
            <span>{value.day}</span>
            {value.timestamp ? format(value.timestamp, 'MMM') : ''}
          </li>
          <li>
            {value.name}
          </li>
          <li>
            {value.weekend ? value.holiday ? 'Weekend & ' + value.holiday : 'Weekend' : value.holiday ? value.holiday : ''}
            {value.sandwich ? 'Long Weekend' : ''}
          </li>
        </ul>
      </div>
    );
  }

  render(){
    const totalLong = this.state.longPrev.length + this.state.longNext.length;

    return (
      <>
        <Helmet>
          <title>DayOff {this.state.date ? this.state.date.timeFormat : ''}</title>
        </Helmet>

        <header>
          <ul>
            <li>
              <a href="/">
                <Logo />
              </a>
              <div className="today" onClick={this.goToday}>
                <span>Today </span><strong>{this.today.toLocaleString('default', { weekday: 'long' })}, {format(this.today, 'd MMMM yyyy')}</strong>
              </div>
            </li>
            <li>
              <button type="button" onClick={this.switchJoint}>
                <span>Include joint leave?</span>
                {this.state.joint ? (<><RiCheckboxCircleFill />Yes</>) : (<><RiCloseCircleFill />No</>)}
              </button>
            </li>
            <li>
              <button type="button" onClick={this.switchHolidayWeekend}>
                <span>Skip holiday on weekend?</span>
                {this.state.holidayWeekend ? (<><RiCheckboxCircleFill />Yes</>) : (<><RiCloseCircleFill />No</>)}
              </button>
            </li>
            <li>
              <button type="button" onClick={this.switchSaturday}>
                <span>Saturday is weekend?</span>
                {this.state.saturday ? (<><RiCheckboxCircleFill />Yes</>) : (<><RiCloseCircleFill />No</>)}
              </button>
            </li>
            <li>
              <button type="button" onClick={this.switchLongWeekend}>
                <span>Include long weekend?</span>
                {this.state.longWeekend ? (<><RiCheckboxCircleFill />Yes</>) : (<><RiCloseCircleFill />No</>)}
              </button>
            </li>
          </ul>
        </header>

        {this.state.pageLoad ? (
          <div className="loading">
            <ul>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
            </ul>
            Loading...
          </div>
        ) : (
          <div className="mid">
            <h1>
              The nearest holiday is <strong>{this.state.date.dayDiff} day{this.state.date.dayDiff > 1 ? 's' : ''} away</strong>,<br />on <strong>{this.state.date.name}</strong>, <strong>{this.state.date.timeFormat}</strong> for the celebration of <strong>{this.state.date.holiday}</strong>.
              {/* Hari libur terdekat <strong>{this.state.date.dayDiff} hari</strong> lagi, pada <strong>{this.state.date.timestamp ? format(this.state.date.timestamp, 'd MMMM yyyy') : ''}</strong> untuk perayaan <strong>{this.state.date.holiday}</strong>. */}
            </h1>
            <div>
              It's <strong>{this.state.date.holidayCount}<sup>{this.renderAkhir(this.state.date.holidayCount)}</sup></strong> holiday in this year{this.state.date.weekend && this.state.date.holiday ? (<>, but <strong>it's not counting</strong> because <strong>{this.state.date.name} is your weekend</strong>.</>) : '.'}
            </div>

            <ul>
              {this.state.longPrev.length ? (
                <li>
                  <ul>
                    {this.state.longPrev.map((value, index) => (
                      <li key={value.dayYear}>
                        {this.renderCard(value)}
                      </li>
                    ))}
                  </ul>
                </li>
              ) : ''}
              <li className="current">
                <ul>
                  <li>
                    {this.renderCard(this.state.date)}
                  </li>
                </ul>
              </li>
              {this.state.longNext.length ? (
                <li>
                  <ul>
                    {this.state.longNext.map((value, index) => (
                      <li key={value.dayYear}>
                        {this.renderCard(value)}
                      </li>
                    ))}
                  </ul>
                </li>
              ) : ''}
            </ul>
            
            {totalLong ? (
              <div>
                This holiday there will be a long weekend of <strong>{totalLong} day{totalLong > 1 ? 's' : ''}</strong>, with {this.state.longPrev.length ? (<strong>{this.state.longPrev.length} day{this.state.longPrev.length > 1 ? 's' : ''} before</strong>) : ''}{this.state.longPrev.length && this.state.longNext.length ? ' and ' : ''}{this.state.longNext.length ? (<strong>{this.state.longNext.length} day{this.state.longNext.length > 1 ? 's' : ''} after</strong>) : ''} the holiday.
              </div>
            ) : ''}
          </div>
        )}
        
        <footer>
          {/* Copyrights &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} <a href="/">DayOff</a> by <a href="nabilamerthabit.com" target="_blank" >Nabil Amer Thabit</a>. All Rights Reserved. */}
          <a href="/">DayOff</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
          <div>Holiday data from <a target="_blank" rel="noreferrer" href="https://dayoffapi.vercel.app/">dayoff-API</a>. We do not guarantee the accuracy or completeness of the information provided.</div>
        </footer>
      </>
    );
  }
}

export default App;
